export default [
  { text: 'Номер брони', value: 'application_code' },
  { text: 'Поставщик', value: 'supplier', width: '200px' },
  { text: 'Дата', value: 'booking_date' },
  { text: 'Измененная дата', value: 'booking_date_x5' },
  { text: 'Кол-во паллет', value: 'pallets_num' },
  { text: 'Измененное кол-во паллет', value: 'pallets_num_x5' },
  { text: 'Статус', value: 'status' },
  { text: 'Измененный статус', value: 'status_x5' },
  { text: 'Номер ТС', value: 'ts_number' },
  { text: 'Измененный номер ТС', value: 'ts_number_x5' }
]
